import axios from "axios"
import { Configuration } from "./configuration"
import { HealthControllerApiFactory, PaymentsApiFactory, TestApiFactory, UserControllerApiFactory } from "./api";

export const API_ADDRESS =
    process.env.RELEASE_ENV === "production" ?
        "production-url" : "development-url"

class APIClientClass {
    public client = axios.create({
        withCredentials: true,
        timeout: 10000,
    })
    private readonly configuration = new Configuration();

    readonly Payments = PaymentsApiFactory(
        this.configuration,
        API_ADDRESS,
        this.client
    )
    readonly Test = TestApiFactory(
        this.configuration,
        API_ADDRESS,
        this.client
    )
    readonly User = UserControllerApiFactory(
        this.configuration,
        API_ADDRESS,
        this.client
    )
    readonly Health = HealthControllerApiFactory(
        this.configuration,
        API_ADDRESS,
        this.client
    )
}

export const APIClient = new APIClientClass()