import { logout } from '@/hooks/member/Member.hooks';
import axios from 'axios';
import { getCookie, setCookie } from 'cookies-next';
import mem from 'mem';

export type GroundResponse<T = {}> = {
  code: number;
  isSuccess: boolean;
  message: string;
  result?: T;
};

export type RefreshTokenResponse = {
  accessToken: string;
  refreshToken: string;
};

const refreshTokenUrl = `${process.env.NEXT_PUBLIC_APP_API}/app/users/renew/token`;

const getRefreshToken = mem(
  async (): Promise<string | void> => {
    const token = getCookie('token');
    const refresh_token = getCookie('refresh_token');

    try {
      const {
        data: { result: { accessToken, refreshToken } = {} },
      } = await axios.post<GroundResponse<RefreshTokenResponse>>(
        refreshTokenUrl,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            refresh_token: `Bearer ${refresh_token}`,
          },
        }
      );

      if (accessToken) setCookie('token', accessToken);
      if (refreshToken) setCookie('refresh_token', refreshToken);
      return accessToken;
    } catch (error) {
      await logout();
      window.location.href = '/';
    }
  },
  { maxAge: 1000 }
);

export const initialize = () => {
  axios.interceptors.response.use(
    (res) => res,
    async (err) => {
      const {
        config,
        response: {
          data: { code },
        },
      } = err;

      if (config.url === refreshTokenUrl || code !== 401 || config.sent) {
        return Promise.reject(err);
      }

      config.sent = true;
      const accessToken = await getRefreshToken();

      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }

      return axios(config);
    }
  );
};
