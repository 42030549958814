import { APIClient } from '../api/client';

const TOAST_BLACKLIST = [''];

export const handleAPIError = () => {
  APIClient.client.interceptors.response.use(
    (res) => res,
    (err) => {
      const LOGIN_TOAST_FLAG = TOAST_BLACKLIST.map((i) =>
        err.config.url.includes(i),
      ).includes(true);

      if (LOGIN_TOAST_FLAG) {
        //! IGNORE THIS FLAG
      } else {
        // SHOW ERROR TOAST
      }

      return Promise.reject(err);
    },
  );
};
